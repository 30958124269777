/* Content.module.css */
.content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
}

.content p {
    font-size: 16px;
    color: #666;
}