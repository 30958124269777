/* Layout.module.css */

.container {
    display: flex;
    height: 100vh;
    margin-top: 55px;
}

.sidebar {
    width: 250px;
    background-color: #16234F;
    color: white;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
    /* Enable vertical scrolling */
    max-height: 100vh;
    /* Limit height to viewport height */
}

.sidebar nav ul {
    list-style-type: none;
    padding: 0;
}

.sidebar nav ul li {
    margin: 2px 0;
    font-family: 'Lato Regular';
}

.sidebar nav ul li a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    display: block;
    padding: 6px;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
}

.sidebar nav ul li a:hover {
    background: linear-gradient(270deg, #D02B37 0%, #772B50 39%, #0D2B6D 100%);
    color: #ecf0f1;
}

.sidebar nav ul li a.active {
    background: linear-gradient(270deg, #D02B37 0%, #772B50 39%, #0D2B6D 100%);
    color: white;
}

.content {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
}
.jobApplicationTable{
    text-align: center;
    color: #0d6efd!important;
}