.appContainer {
    display: flex;
    height: 100vh;
    margin-top: 60px;
}

.sideMenu {
    width: 200px;
    background-color: #f4f4f4;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.menuItem {
    margin: 5px 0;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    cursor: pointer;
}

.menuItem.active {
    background-color: #ccc;
}


.menuItem.active {
    background-color: #162340;
    color: #fff;
    border-color: #162340;
}

.sideMenu {
    width: 200px;
    background-color: #f4f4f4;
    padding: 20px;
}

.sideMenu ul {
    list-style: none;
    padding: 0;
}

.sideMenu li {
    padding: 10px;
    cursor: pointer;
}

.sideMenu li.active {
    background-color: #ddd;
}

.content {
    padding: 10px;
    background-color: #f9f9f9;
    flex-grow: 1;
    height: fit-content;
    text-align: left;
}

button {
    width: auto;
}