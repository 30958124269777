body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f9;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
}

.appContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
}


.header {
    background-color: #162340;
    padding: 10px;
    color: white;
    text-align: center;
}

.title {
    margin: 0;
    font-size: 2em;
}


.Loginheader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
}

.loginContainer {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
}

.loginContainer h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.formGroup {
    margin-bottom: 20px;
    text-align: left;
}

.formGroup label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.formGroup input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

button {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.error {
    color: red;
    margin-bottom: 20px;
}

.success {
    color: green;
    margin-top: 20px;
}

.LabelStyle {
    display: flex;
}

.passwordContainer {
    position: relative;
}

.showPasswordButton {
    position: absolute;
    right: 10px;
    width: 45px;
    color: black;
    top: 30%;
    transform: translateY(-50%);
    background: none;
    border: none;
}

.showPasswordButton:focus {
    outline: none;
}

.ButtonStyle {
    margin: 10px 0px;
    width: -webkit-fill-available;
}

.rgisterpassword {
    float: left;
}

.forgotPasswordLink {
    float: right;
}

.CancleButton {
    padding: 5px;
    /* border: 1px solid #cccccc; */
    border-radius: 5px;
}