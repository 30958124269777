@font-face {
  font-family: 'Sora-Regular';
  src: local('Sora-Regular'), url('./assest/fonts/Sora-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Sora-Bold';
  src: local('Sora-Bold'), url('./assest/fonts/Sora-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Sora-SemiBold';
  src: local('Sora-SemiBold'), url('./assest/fonts/Sora-SemiBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Lexend Deca Light';
  src: local('lexend deca Light'), url('./assest/fonts/lexend-deca-latin-200.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Lexend Deca Light 300';
  src: local('lexend deca Light 300'), url('./assest/fonts/lexend-deca-latin-300.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Lexend Deca Regular';
  src: local('Lexend Deca Regular'), url('./assest/fonts/lexend-deca-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Lexend Deca Medium';
  src: local('lexend deca Medium'), url('./assest/fonts/lexend-deca-latin-500.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Lexend Deca SemiBold';
  src: local('lexend deca SemiBold'), url('./assest/fonts/lexend-deca-latin-600.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Lexend Deca bold';
  src: local('lexend deca Bold'), url('./assest/fonts/lexend-deca-latin-700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Lato Regular';
  src: local('Lato Regular'), url('./assest/fonts/Lato-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Lato Bold';
  src: local('Lato Bold'), url('./assest/fonts/Lato-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Lexend Deca Regular', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 25px;
  overflow-x: hidden;
  color: #000;
}
img {max-width: 100%;height: auto;}

@media only screen and (min-width:1600px){
  .container{max-width: 1440px;}
}
ul, ol{margin: 0;padding: 0;}
li{list-style-type: none;}
a{text-decoration: none!important;}
figure{margin: 0;}
h1{font-family: 'Sora-Bold';font-size: 65px;line-height: 75px;}
h2{font-family: 'Sora-Bold';font-size: 55px;line-height: normal;}
h3{font-family: 'Sora-Bold';font-size: 50px;line-height: 53px;text-transform: capitalize;}
h4{font-family: 'Sora-Bold';font-size: 35px;}
h5{font-family: 'Sora-SemiBold';font-size: 21px;}
h6{font-family: 'Lexend Deca Medium';font-size: 16px;}
header{padding: 15px 0;position: fixed;top: 0;width: 100%;z-index: 9999;transition: all 0.5s ease;}
header.fixHeader{padding: 0;background: #fff;    box-shadow: 0 5px 10px #00000026;}


ul.offTabStyle{margin: 0;padding: 0;flex-direction: column;border: none;}
ul.offTabStyle li{padding: 0;border: none;font-family: 'Lexend Deca Medium';color: #fff;width: 100%;text-align: left;font-size: 16px;line-height: 24px;padding: 15px 30px;border-radius: 0;border-bottom: 1px solid #3156A7;}
ul.offTabStyle li.react-tabs__tab--selected{background: linear-gradient(270deg, #ED1F1D 0%, #ED1F1D 39%, #0D2B6D 100%);border: none;}
ul.offTabStyle li.react-tabs__tab:focus:after{content: none;}
ul.offTabStyle li:last-child{border: none;}
.leaderSliderContainer{position: relative;margin-top: -45px;}
.leaderSwiper{position: relative;padding-top: 60px;}
.leaderSwiper .swiper-button-prev, .leaderSwiper .swiper-button-next{position: absolute;top: 23px;right: 0;width: 55px;height: 34px;background:#0054a5 ;left: auto;}
.leaderSwiper .swiper-button-prev:hover, .leaderSwiper .swiper-button-next:hover{background: #ED1F1D;}
.leaderSwiper .swiper-button-prev{right: 60px;}
.leaderSwiper .swiper-button-prev:after{transform: rotate(180deg);}
.leaderSwiper .swiper-button-next:after, .leaderSwiper .swiper-button-prev:after{content: '→';color: #fff;font-size: 22px;}

.leaderSliderContainer .leaderSwiperThum{position: absolute;bottom: 80px;width: 100%;padding-left: 50px;}
.leaderSliderContainer .leaderSwiperThum .swiper-slide{width: 185px!important;}
.leaderSliderContainer .leaderSwiperThum .swiper-slide-thumb-active>div{background: #fff;opacity: 1;}
.leaderSliderContainer .leaderSwiperThum .swiper-slide-thumb-active h6{color: #040D33;}
.leaderSliderContainer .leaderSwiperThum .swiper-slide-thumb-active h6 span{color: #ED1F1D;}
.leaderSliderContainer .leaderSwiperThum .swiper-slide-thumb-active>div figure{border-color: #EDE1E1;}
.leaderPageOwl .react-multi-carousel-item>div{position: relative;margin: 0 15px;}
.leaderPageOwl .react-multi-carousel-item>div:after{position: absolute;content: "";top: 0;left: 0;width: 100%;height: 100%;background: #fff;opacity: 0.8;}
.leaderPageOwl .react-multi-carousel-item--active>div:after{background: transparent;}
.arrowStyle .react-multiple-carousel__arrow{width: 58px;height: 40px;background: #0054a5;border-radius: 0;margin: 0 80px;}
.arrowStyle .react-multiple-carousel__arrow::before {content: "→";line-height: 0;font-size: 30px;top: -2px;}
.arrowStyle .react-multiple-carousel__arrow--left{transform: rotate(180deg);}
.arrowStyle .react-multiple-carousel__arrow--left::before{top: 0;}
.sectors .arrowStyle .react-multiple-carousel__arrow {margin: 0;width: 50px;height: 50px;padding: 0;background: #ED1F1D;}
.sectors .arrowStyle .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {left: 44.5%;}
.sectors .arrowStyle .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {right: 46%;}
.sectors .arrowStyle .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left:before {top: 0;}
.sectors .arrowStyle .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right:before {top: -1px;}
.megaMenuStyle .offTabStyle li{margin: 0;font-size: 14px;padding: 15px 35px 15px 20px;text-align: left;font-family: 'Lexend Deca Regular';}
.dotsStyle .react-multi-carousel-dot-list button{width: 24px;height: 7px;background: #ED1F1D;border-radius: 70px;border: none;margin-right: 4px;}
.dotsStyle .react-multi-carousel-dot--active button{width: 7px;height: 7px;}
.dotsStyle{padding-bottom: 0;}
.tabbingStyle .react-tabs__tab.react-tabs__tab--selected{color: #ED1F1D;}
.breadCrmStyle ul li a.active{color: #ED1F1D;}
table, th, td {
  border-collapse: collapse;
}
.pagination{position: relative;display: flex;justify-content: center;align-items: center;margin: 0 0 30px;}
.pagination button{    margin: 0;border-radius: 4px;padding: 10px;line-height: normal;width: 28px;height: 30px;display: flex;align-items: center;justify-content: center;}
.megaMenuStyle .react-tabs__tab-panel {padding: 25px 50px;height: 100%;}
.megaMenuStyle .react-tabs__tab-panel--selected {background-color: #ED1F1D;}

.lookingSec .arrowStyle .react-multiple-carousel__arrow {margin: 0;width: 40px;height: 40px;padding: 0;background: #ED1F1D;}
.lookingSec .arrowStyle .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {left: 45%;}
.lookingSec .arrowStyle .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {right: 46%;}
.lookingSec .arrowStyle .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left:before {top: 3px;font-size: 25px;}
.lookingSec .arrowStyle .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right:before {top: -1px;font-size: 25px;}

.dotsStyle{position: relative;}
.dotsStyle .react-multi-carousel-dot-list {position: absolute;bottom: 10px;left: 0;width: 100%;}
.megaMenuStyle ul.offTabStyle li.react-tabs__tab--selected{background: linear-gradient(270deg, #ED1F1D 0%, #ED1F1D 39%, #0054a5 100%);}
/* .megaMenuStyle ul.offTabStyle li{border-bottom: 1px dotted #3156A7;} */

.arrowStyle{padding-bottom: 60px;}
.arrowStyle .react-multiple-carousel__arrow{background: #0865bf;margin: 0;}
.arrowStyle .react-multiple-carousel__arrow--left{bottom: 0;left: 45%;right: auto;}
.arrowStyle .react-multiple-carousel__arrow--right{right: 45%;bottom: 0;left: auto;}

@media only screen and (max-width:1500px){
  .sectors .arrowStyle .react-multiple-carousel__arrow{margin: 0 -10px;}
}

@media only screen and (max-width:1400px){
  .sectors .arrowStyle .react-multiple-carousel__arrow{margin: 0 0;}
h1 {font-size: 55px;line-height: 65px;}   
h2{font-size: 45px;}
h4{font-size: 28px;}
h6{font-size: 14px;}
h5{font-size: 18px;}
.leaderSliderContainer .leaderSwiperThum{padding-left: 30px;bottom: 60px;} 
.leaderSliderContainer .leaderSwiperThum .swiper-slide{width: 150px!important;}
.heroBannerContent{top: 55%;}
.leaderPageOwl .react-multi-carousel-item>div{margin: 0 10px;}
.arrowStyle .react-multiple-carousel__arrow::before{font-size: 19px;}
.sectors .arrowStyle .react-multiple-carousel__arrow{    width: 40px;height: 30px;padding: 0;background: #ED1F1D;}
.sectors .arrowStyle .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left:before {top: 0;}
.arrowStyle .react-multiple-carousel__arrow--left{left: 44.5%;}
.arrowStyle .react-multiple-carousel__arrow--right{right: 44.5%;}
}

@media only screen and (max-width:1199px){
  .arrowStyle .react-multiple-carousel__arrow--left{left: 43.5%;}
.arrowStyle .react-multiple-carousel__arrow--right{right: 43.5%;}
  .sectors .arrowStyle .react-multiple-carousel__arrow{margin: 0 -5px;}
.breadCrmStyle .container {max-width: 100%;}
body{font-size: 14px;}  
h1 {font-size: 50px;line-height: 60px;}  
h3{font-size: 40px;line-height: 43px;}
ul.offTabStyle li{font-size: 14px;padding: 15px 20px;}
.leaderSliderContainer .leaderSwiperThum {bottom: 30px;}
.leaderSwiper .swiper-button-prev:after{position: relative;top: 2px;}
.leaderSwiper .swiper-button-next:after{position: relative;top: -2px;}
}

@media only screen and (max-width:991px){
  h1 {font-size: 40px;line-height: 45px;}
  h3{font-size: 30px;line-height: 33px;}  
  .leaderSliderContainer .leaderSwiperThum .swiper-slide{width: 180px!important;}
header ul.offTabStyle{flex-direction: column!important;display: flex!important;}
.sectors .arrowStyle .react-multiple-carousel__arrow {width: 40px;height: 40px;} 
.sectors .arrowStyle .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left:before {font-size: 25px;}
.sectors .arrowStyle .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right:before {top: -1px;font-size: 25px;}
.megaMenuStyle .react-tabs__tab-panel{padding: 0;}
.megaMenuStyle .react-tabs__tab-panel--selected{border-radius: 0;}
.sectors .arrowStyle .react-multiple-carousel__arrow{margin: 0 -15px;}
.sectors .arrowStyle .react-multiple-carousel__arrow {width: 40px;height: 35px;min-height: 35px;}
ul.offTabStyle li{padding: 15px;}
.arrowStyle .react-multiple-carousel__arrow--left{left: 40%;}
.arrowStyle .react-multiple-carousel__arrow--right{right: 40%;}
}

@media only screen and (max-width:767px){
  .sectors .arrowStyle .react-multiple-carousel__arrow{margin: 0 -25px;}
  body, p{font-size: 13px;line-height: 22px;}
.heroBannerContent{padding: 0 0;width: auto;}
h1 {font-size: 35px;line-height: 45px;}
ul.offTabStyle{flex-direction: row;justify-content: center;margin-bottom: 20px;}
ul.offTabStyle li{font-size: 12px;padding: 5px;width: auto;margin: 0 0;border: none;}
header .container{max-width: 100%;}
.mobMenuInner{display: none!important;}
.active-class{display: flex!important;}
}
@media only screen and (max-width:580px){
  .sectors .arrowStyle .react-multiple-carousel__arrow{margin: 0 -30px;}
  .arrowStyle .react-multiple-carousel__arrow{margin: 0;}
  .arrowStyle .react-multiple-carousel__arrow--right{right: 15px;}
  .arrowStyle .react-multiple-carousel__arrow--left{left: 15px;}
  ul.offTabStyle li{font-size: 11px;padding: 5px;}
}

.sectionLoader {
  width: 100%;
  text-align: center;
  top: 0;
  position: fixed;
  height: 100vh;
  left: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.hideloader div{display: none;}
.sectionLoader div {
  justify-content: center;
}


.carousel-container {
  position: relative;
  width: 540px;
  margin: auto;
}

.carousel-slide {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-content {
  width: 100%;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 50%;
  position: relative;
  flex-shrink: 0;
  opacity: 0;
  transition: all 0.5s ease;
}
.carousel-content.active{opacity: 1;transition: all 0.5s ease;}

.carousel-navigation {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  pointer-events: none;
}

.carousel-navigation button {
  background: #fff;
  border: 2px solid #000;
  padding: 10px 20px;
  cursor: pointer;
  pointer-events: auto;
  border-radius: 20px;
}

.carousel-navigation button:hover {
  background-color: #ddd;
}
.carouselcontent{z-index: 1;}
.carouselcontent.active{z-index: 9;}

.worldmap__figure-container{background: transparent!important;width: 100%;display: flex;justify-content: center;}
.countryName{font-family: 'Sora-SemiBold';font-size: 10px;text-shadow: -3px 0 20px #000;padding: 10px;cursor: pointer;font-size: 10px;fill:#fff}

.st0{fill:#e0e0e0;stroke:#FFFFFF;stroke-width:0.25;stroke-miterlimit:10;}
.st1{fill:#2F9DDE;stroke:#FFFFFF;stroke-width:0.25;stroke-miterlimit:10;}
.st2{fill:#3BC195;stroke:#FFFFFF;stroke-width:0.25;stroke-miterlimit:10;}
.st3{fill:#6F5ED4;stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
.st4{fill:#64C3D1;}
.st5{fill:#143AD3;stroke:#FFFFFF;stroke-width:0.25;stroke-miterlimit:10;}
.st6{fill:#9DD314;stroke:#FFFFFF;stroke-width:0.25;stroke-miterlimit:10;}
.st7{fill:#333333;}
.lightBlue{fill:#5b9bd5;stroke:#FFFFFF;stroke-width:0.25;stroke-miterlimit:10;}
.orangBlue{fill:#ed7d31;stroke:#FFFFFF;stroke-width:0.25;stroke-miterlimit:10;}
.brownBlue{fill:#a5a5a5;stroke:#FFFFFF;stroke-width:0.25;stroke-miterlimit:10;}
.greenBlue{fill:#70ad47;stroke:#FFFFFF;stroke-width:0.25;stroke-miterlimit:10;}
.BlueDark{fill:#4472c4;stroke:#FFFFFF;stroke-width:0.25;stroke-miterlimit:10;}
.yellowDark{fill:#ffc000;stroke:#FFFFFF;stroke-width:0.25;stroke-miterlimit:10;}
/* .hoverEfect{display: none;}
.hoverEf{display: none;} */
.mouseOver:hover .hoverEfect, .mouseOver:hover .hoverEf{display: block;}
.hoverEf{display: none;}
.show{display: block;}

